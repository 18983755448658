import * as THREE from 'three'
import HomeScene from './subscenes/HomeScene'
import { MOBILE_BP } from '../../helpers/sizes'
import { GlobalEventBus } from '../../helpers/eventBus.js'

class ThreeCanvas {

	constructor(projects, currentProjectIndex = 0) {
		if (typeof window !== `undefined`) {
			this.sizes = {
				width: window.innerWidth,
				height: window.innerHeight
			}
		}
		this.projects = projects
		this.currentProjectIndex = currentProjectIndex
		this.rafId = null
		this.lastTouch = 0
	}

	init(threeContainerEl) {
		this.threeContainerEl = threeContainerEl
		this.setupRenderer()
		this.setupTextures()
		this.setupScene()
		this.setupEvents()
	}

	setupRenderer() {
		this.renderer = new THREE.WebGLRenderer({ alpha: true })
		this.renderer.setSize(this.sizes.width, this.sizes.height)
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		this.threeContainerEl.appendChild(this.renderer.domElement);
	}

	setupTextures() {
		this.loadingManager = new THREE.LoadingManager()
		this.textureLoader = new THREE.TextureLoader(this.loadingManager)

		// this.texturesLoaded = 0
		// const handleTextureLoaded = () => {
		// 	this.texturesLoaded += 1
		// 	if (this.texturesLoaded >= this.textures.length * 2) {
		// 		GlobalEventBus.emit('onHomeCanvasTexturesLoaded')
		// 	}
		// }

		this.textures = this.projects.map((project, index) => {
			const nameTexture = this.textureLoader.load(project.data.circular_text_texture.url)
			nameTexture.repeat.x = project.data.name_repeat_times || 4
			nameTexture.wrapS = THREE.RepeatWrapping
			return {
				name: nameTexture,
				images: [
					this.textureLoader.load(project.data.image_1.url),
					this.textureLoader.load(project.data.image_2.url)
				]
			}
		})
	}

	setupScene() {
		this.scene = new THREE.Scene()

		// Camera(s)
		this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100)
		this.camera.position.z = this.sizes.width <= MOBILE_BP ? 18 : 15
		this.scene.add(this.camera)

		// Controls
		// this.controls = new OrbitControls(this.camera, this.renderer.domElement)
		// this.controls.enableDamping = true

		// Add subcenes and objects to the main scene
		this.homeScene = new HomeScene(this.textures, this.projects.length, this.currentProjectIndex)
		this.scene.add(this.homeScene.scene)

	}

	setupEvents() {
		window.addEventListener('resize', this.handleResize.bind(this))
		window.addEventListener('wheel', this.handleWheel.bind(this))
		window.addEventListener('touchstart', this.handleTouchStart.bind(this))
		window.addEventListener('touchmove', this.handleTouchMove.bind(this))
		// GlobalEventBus.on('onHomeGoToPrevProject', this.handleHomeGoToPrevProject.bind(this))
		// GlobalEventBus.on('onHomeGoToNextProject', this.handleHomeGoToNextProject.bind(this))
	}

	handleResize() {
		// Update sizes
		this.sizes.width = window.innerWidth
		this.sizes.height = window.innerHeight

		// Update camera
		this.camera.aspect = this.sizes.width / this.sizes.height
		this.camera.updateProjectionMatrix()

		// Update renderer
		this.renderer.setSize(this.sizes.width, this.sizes.height)
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
	}

	handleWheel(e) {
		this.homeScene.handleWheel(e)
	}

	handleTouchStart(e) {
		this.lastTouch = e.touches[0].screenY
	}

	handleTouchMove(e) {
		const movement = this.lastTouch - e.touches[0].screenY
		this.homeScene.handleTouchMove(movement)
		this.lastTouch = e.touches[0].screenY
	}

	// handleHomeGoToPrevProject() {
	// 	this.homeScene.goToPrevProject()
	// }

	// handleHomeGoToNextProject() {
	// 	this.homeScene.goToNextProject()
	// }

	tick() {
		// Update controls
  	// this.controls.update()

		// Update subscenes and objects
		this.homeScene.tick()

		// Render
		this.renderer.render(this.scene, this.camera)

		this.rafId = window.requestAnimationFrame(this.tick.bind(this))
	}

	updateCurrentProjectIndex(newIndex) {
		this.homeScene.updateCurrentProjectIndex(newIndex)
	}

	outAnimation(dir) {
		this.homeScene.outAnimation(dir)
	}

	inAnimation() {
		this.homeScene.inAnimation()
	}

	destroy() {
		window.cancelAnimationFrame(this.rafId)
		window.removeEventListener('resize', this.handleResize.bind(this))
		window.removeEventListener('wheel', this.handleWheel.bind(this))
		// GlobalEventBus.off('onHomeGoToPrevProject', this.handleHomeGoToPrevProject)
		// GlobalEventBus.off('onHomeGoToNextProject', this.handleHomeGoToNextProject)
		this.threeContainerEl.removeChild(this.renderer.domElement)
	}

}

export default ThreeCanvas