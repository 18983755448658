import React, { useEffect, useRef } from 'react'
import { GlobalEventBus } from '../helpers/eventBus'
import prepareGradient from '../helpers/prepareGradient'

/*
*	This component handles both the global colors change
* and the background color transition with the gradients
* when the it catches the event onBackgroundColorChange
* from the global event bus
*/

const Background = () => {

	const transitionEl = useRef(null)

	useEffect(() => {

		const handleHomeColorChange = e => {
			const newProjectData = e.detail.newProjectData.data
			changeHomeBackgroundColor(newProjectData)
			document.documentElement.style.setProperty('--colorPagination', newProjectData.color_pagination)
			document.documentElement.style.setProperty('--colorDescription', newProjectData.color_description)
			document.documentElement.style.setProperty('--colorProjects', newProjectData.color_projects)
			document.documentElement.style.setProperty('--colorLogo', newProjectData.color_logo)
			document.documentElement.style.setProperty('--colorAboutBackground', prepareGradient(newProjectData.color_about_background))
			document.documentElement.style.setProperty('--colorAboutHeadline', newProjectData.color_about_headline)
			document.documentElement.style.setProperty('--colorAboutLogo', newProjectData.color_about_logo)
			document.documentElement.style.setProperty('--colorTransitionBackground', newProjectData.color_transition_background)
		}

		const changeHomeBackgroundColor = (newProjectData) => {
			const newHomeBgColor = prepareGradient(newProjectData.color_background)
			transitionEl.current.style = `background: ${newHomeBgColor}`
			transitionEl.current.classList.add('visible')
			setTimeout(() => {
				document.documentElement.style.setProperty('--colorBackground', newHomeBgColor)
				transitionEl.current.classList.remove('visible')
			}, 500);
		}

		GlobalEventBus.on('onHomeColorChange', handleHomeColorChange)
		return () => {
			GlobalEventBus.off('onHomeColorChange', handleHomeColorChange)
		}
	}, [])

	return (
		<div className="Background">
			<div className="Background__TransitionEl" ref={transitionEl}></div>
		</div>
	)
}

export default Background