import { Scene } from 'three'
import CylinderText from '../objects/CylinderText'
import SpikedImages from '../objects/SpikedImages'
import normalizeWheel from '../../../helpers/normalizeWheel'
import limitNumberWithinRange from '../../../helpers/limitNumberWithinRange'
import lerp from '../../../helpers/lerp'
import { GlobalEventBus } from '../../../helpers/eventBus.js'

const HOME_SCENE_DEFAULT_ROTATION_RATIO = 0.003

class HomeScene {

	constructor(textures, projectsLength, currentProjectIndex) {
		this.textures = textures
		this.projectsLength = projectsLength
		this.currentProjectIndex = currentProjectIndex
		this.scene = new Scene()
		this.scene.name = 'HomeScene'

		this.rotationRatio = HOME_SCENE_DEFAULT_ROTATION_RATIO
		this.rotationLength = Math.PI
		// The init position of the image is not on the side circle so it only half a PI rotation to switch either way
		this.currentRotationLimits = {
			min: -this.rotationLength * 0.5,
			max: this.rotationLength * 0.5
		}
		this.speed = 2
		this.targetSpeed = 2
		this.timeoutTargetSpeed = null

		this.isTransitioningToPrevProject = false
		this.isTransitioningToNextProject = false

		this.setupScene()
	}

	setupScene() {
		// Init objects
		this.cylinderText = new CylinderText(this.textures.map(item => item['name']), this.currentProjectIndex)
		this.scene.add(this.cylinderText.object)

		this.spikedImages = new SpikedImages(this.textures.map(item => item['images']), this.currentProjectIndex)
		this.scene.add(this.spikedImages.object)
	}

	handleWheel(e) {
		const normalizedEvent = normalizeWheel(e)
		this.targetSpeed = limitNumberWithinRange(normalizedEvent.pixelY, -60, 60)
		// TODO: go back to speed 1 with an easing function?
		clearTimeout(this.timeoutTargetSpeed)
		this.timeoutTargetSpeed = setTimeout(() => {
			this.targetSpeed = 2
		}, 50)
	}

	handleTouchMove(movement) {
		this.targetSpeed = movement * 8
		clearTimeout(this.timeoutTargetSpeed)
		this.timeoutTargetSpeed = setTimeout(() => {
			this.targetSpeed = 2
		}, 50)
	}

	// Detect project switch thanks to image rotation Y
	detectProjectSwitch(imageRotationY) {
		if (imageRotationY < this.currentRotationLimits.min) {
			this.currentRotationLimits.min -= this.rotationLength
			this.currentRotationLimits.max -= this.rotationLength
			this.switchCurrentProject(-1)
			// if (this.isTransitioningToPrevProject) {
			// 	this.targetSpeed = 2
			// 	this.isTransitioningToPrevProject = false
			// }
		}
		else if (imageRotationY > this.currentRotationLimits.max) {
			this.currentRotationLimits.min += this.rotationLength
			this.currentRotationLimits.max += this.rotationLength
			this.switchCurrentProject(1)
			// if (this.isTransitioningToNextProject) {
			// 	this.targetSpeed = 2
			// 	this.isTransitioningToNextProject = false
			// }
		}
	}

	switchCurrentProject(inc) {
		let newCurrentProjectIndex = this.currentProjectIndex

		newCurrentProjectIndex += inc
		if (newCurrentProjectIndex < 0) {
			newCurrentProjectIndex = this.projectsLength - 1
		}
		else if (newCurrentProjectIndex >= this.projectsLength) {
			newCurrentProjectIndex = 0
		}

		this.spikedImages.currentProjectIndex = newCurrentProjectIndex
		this.spikedImages.isReversed = !this.spikedImages.isReversed
		this.spikedImages.updateTextures()
		this.cylinderText.currentProjectIndex = newCurrentProjectIndex
		this.cylinderText.isReversed = !this.cylinderText.isReversed
		this.cylinderText.updateTextures()

		GlobalEventBus.emit('onHomeSwitchCurrentProject', {
			newIndex: newCurrentProjectIndex,
			direction: inc
		})
	}

	updateCurrentProjectIndex(newIndex) {
		this.currentProjectIndex = newIndex
	}

	// goToPrevProject() {
	// 	this.targetSpeed = -30
	// 	this.isTransitioningToPrevProject = true
	// }

	// goToNextProject() {
	// 	this.targetSpeed = 30
	// 	this.isTransitioningToNextProject = true
	// }

	inAnimation() {
		this.spikedImages.inAnimation()
		this.cylinderText.inAnimation()
	}

	outAnimation(dir) {
		this.spikedImages.outAnimation(dir)
		this.cylinderText.outAnimation(dir)
	}

	tick() {
		this.speed = lerp(this.speed, this.targetSpeed, 0.027)

		// Update image rotation and get it's new rotation to know when to switch project
  	const newImageRotationY = this.spikedImages.updateRotation(this.rotationRatio, this.speed)

		// Update text rotation
  	this.cylinderText.updateRotation(this.rotationRatio, this.speed)

		// Detect project switch thanks to image rotation
		this.detectProjectSwitch(newImageRotationY)
	}
}

export default HomeScene