import {
	Group,
	CylinderGeometry,
	MeshBasicMaterial,
	DoubleSide,
	Mesh,
	Vector2
} from 'three'
import { gsap } from 'gsap'

class CylinderText {

	constructor(textures, currentProjectIndex) {
		this.textures = textures
		this.currentProjectIndex = currentProjectIndex
		this.object = new Group()
		this.object.name = 'CylinderText'
		this.isReversed = false

		this.init()
	}

	init() {
		const geometry = new CylinderGeometry(7, 7, 2.8, 30, 1, true)
		const material = new MeshBasicMaterial({
			map: this.textures[this.currentProjectIndex],
			side: DoubleSide,
			transparent: true,
			depthWrite: false
		})
		this.mesh = new Mesh(geometry, material)
		this.object.rotation.z = Math.PI * 0.25
		this.object.add(this.mesh)
	}

	updateRotation(rotationRatio, speed) {
		this.mesh.rotation.x -= rotationRatio * speed
  	this.mesh.rotation.y -= 0.01 * speed
	}

	updateTextures() {
		const newTexture = this.textures[this.currentProjectIndex]
		newTexture.center = new Vector2(0.5, 0.5)
		newTexture.rotation = this.isReversed ? Math.PI : 0
		this.mesh.material.map = newTexture
  	this.mesh.material.map.needsUpdate = true
	}

	inAnimation() {
		const tl = gsap.timeline()
		tl.add(gsap.set(this.mesh.rotation, {y: '+=1'}), 0)
		tl.add(gsap.to(
			this.mesh.rotation,
			0.3,
			{
				y: '-=1',
				ease: 'circ.easeIn'
			}
		), 0.3)
	}

	outAnimation(dir) {
		gsap.to(
			this.mesh.rotation,
			0.3,
			{
				y: dir === 'left' ? '-=1' : '+=1',
				ease: 'circ.easeOut'
			}
		)
	}
}

export default CylinderText