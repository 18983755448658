import {
	Group,
	SpotLight,
	BufferGeometry,
	Float32BufferAttribute,
	DoubleSide,
	MeshBasicMaterial,
	Mesh,
	RepeatWrapping
} from 'three'
import { gsap } from 'gsap'

class SpikedImages {

	constructor(textures, currentProjectIndex) {
		this.object = new Group()
		this.object.name = 'SpikedImages'
		this.textures = textures
		this.currentProjectIndex = currentProjectIndex
		this.imageW = 10
		this.imageH = 10
		this.spikesW = this.imageW * 30
		this.spikesH = this.imageH * 30
		this.imagesGroup = new Group()
		this.imagesGroup.name = 'ImagesGroup'
		this.object.add(this.imagesGroup)
		this.vs = []
		this.geometries = []
		this.isReversed = false

		this.init()
	}

	init() {
		this.initLights()
		this.prepareVertices()
		this.initGeometries()
		this.initMeshes()
	}

	initLights() {
		const lightLeft = new SpotLight(0xfff, 1, this.imageW, Math.PI / 2, 0, 0)
		lightLeft.position.set(-this.imageW, 0, 0);
		this.object.add(lightLeft)

		const lightRight = new SpotLight(0xfff, 1, this.imageW, Math.PI / 2, 0, 0)
		lightRight.position.set(this.imageW, 0, 0);
		this.object.add(lightRight)
	}

	prepareVertices() {
		// Prepare vertices uv and xyz coordinates
		for (let i = 0; i < this.spikesH; i +=1) {
			this.vs[i] = []
			const normY = i / (this.spikesH - 1) // Normalized Y pos
			for (let j = 0; j < this.spikesW; j +=1) {
				const normX = j / (this.spikesW - 1); // Normalized X pos
				this.vs[i][j] = {
					uv: [normX, normY],
					xyz: [
						// PosX and PosY centered from -0.5 to 0.5 and scaled to W and H:
						(normX - 0.5) * this.imageW,
						(normY - 0.5) * this.imageH,
						// PosZ making spikes:
						(i + 1) % 2 * (j % 2) * 0.5 - 0.25
					]
				}
			}
		}
	}

	initGeometries() {
		// Geometry: create 2 geometries, one for each image of the project
		for (let k = 0; k <= 1; k += 1) {
			const geometry = new BufferGeometry()
			const N = ((this.spikesW - k) * 0.5) * (this.spikesH - 1) // Not sure why we substract k to SW here
			const pos = new Float32Array(N * 3 * 6); // six (x,y,z)
			const uv = new Float32Array(N * 2 * 6); // six (u,v)
			let n = 0;
			// Setting the values inside the pos and uv Float32Arrays
			// Second argument of .set is the index at which you want to start putting
			// the values. We are incrementing n and multipliying it by 3 on pos because
			// there are grouped by 3 values (x,y and z) and by 2 on uv because
			// there are grouped by 2 values (u and v)
			for (let i = 0; i < this.spikesH - 1; i += 1) {
				for (let j = k; j < this.spikesW - 1; j += 2) {
					let v = this.vs[i][j]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
					v = this.vs[i][j + 1]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
					v = this.vs[i + 1][j]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
					v = this.vs[i][j + 1]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
					v = this.vs[i + 1][j + 1]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
					v = this.vs[i + 1][j]
					pos.set(v.xyz, n * 3)
					uv.set(v.uv, n * 2)
					++n
				}
			}
			geometry.setAttribute('position', new Float32BufferAttribute(pos, 3))
			geometry.setAttribute('uv', new Float32BufferAttribute(uv, 2))
			geometry.computeVertexNormals()
			this.geometries.push(geometry)
		}
	}

	initMeshes() {
		this.meshes = []
		for (let i = 0; i <= 1; i += 1) {
			const material = new MeshBasicMaterial({
				map: this.textures[this.currentProjectIndex][i],
				side: DoubleSide
			})
			this.meshes.push(new Mesh(this.geometries[i], material))
			this.imagesGroup.add(this.meshes[i])
		}
	}

	updateRotation(rotationRatio, speed) {
		const newImageRotationY = this.imagesGroup.rotation.y + rotationRatio * speed
		this.imagesGroup.rotation.y = newImageRotationY
		return newImageRotationY
	}

	updateTextures() {
		for (let i = 0; i <= 1; i += 1) {
			const texture = this.textures[this.currentProjectIndex][i]
			if (this.isReversed) {
				texture.wrapS = RepeatWrapping
				texture.repeat.x = - 1
			} else {
				texture.wrapS = 1001
				texture.repeat.x = 1
			}
			this.meshes[i].material.map = texture
			this.meshes[i].material.map.needsUpdate = true
		}
	}

	inAnimation() {
		const tl = gsap.timeline()
		tl.add(gsap.set(this.object.rotation, {y: '-=1'}), 0)
		tl.add(gsap.to(
			this.object.rotation,
			0.5,
			{
				y: '+=1',
				ease: 'circ.easeIn'
			}
		), 0.25)
	}

	outAnimation(dir) {
		gsap.to(
			this.object.rotation,
			0.5,
			{
				y: dir === 'left' ? '-=1' : '+=1',
				ease: 'circ.easeOut'
			}
		)
	}

}

export default SpikedImages