import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { GlobalEventBus } from '../helpers/eventBus'

const ProjectsListHome = ({ projects, currentProjectInfos, onProjectClick }) => {

	const projectsOffset = 4

	const nextListContainerEl = useRef(null)
	const nextListItemsEls = useRef([])
	const [nextProjects, setNextProjects] = useState([])

	const prevListContainerEl = useRef(null)
	const prevListItemsEls = useRef([])
	const [prevProjects, setPrevProjects] = useState([])

	const projectsListHomeEl = useRef(null)

	// Init lists
	useEffect(() => {
		projectsListHomeEl.current.classList.add('hidden')
		setTimeout(() => {
			// If first index is at the beggining of the project list, add last projects to the prev list
			if (currentProjectInfos.index < projectsOffset) {
				const newPrevProjects = projects.slice(0, currentProjectInfos.index)
				newPrevProjects.unshift(...projects.slice(projects.length - (projectsOffset - newPrevProjects.length), projects.length))
				setPrevProjects(newPrevProjects)
			}
			else {
				setPrevProjects(projects.slice(currentProjectInfos.index - projectsOffset, currentProjectInfos.index + projectsOffset))
			}

			// If init index is at the end of the projects list, add first projects to the next list
			if (currentProjectInfos.index >= projects.length - projectsOffset) {
				const newNextProjects = projects.slice(currentProjectInfos.index + 1, projects.length)
				newNextProjects.push(...projects.slice(0, projectsOffset - newNextProjects.length))
				setNextProjects(newNextProjects)
			} else {
				setNextProjects(projects.slice(currentProjectInfos.index + 1, currentProjectInfos.index + projectsOffset + 1))
			}
			projectsListHomeEl.current.classList.remove('hidden')
		}, 500);
	}, [projects])

	// Update lists when current project index changes in -1 or +1 (not with a go to or a canvas init/reinit)
	useEffect(() => {
		if (!currentProjectInfos.direction) return

		const updatePrevListItems = () => {
			// Add a new project before or after depending on direction
			if (currentProjectInfos.direction === 1) {
				let lastProject = projects[currentProjectInfos.index - 1]
				if (!lastProject) {
					lastProject = projects[projects.length - 1]
				}
				const prevProjectsCopy = [...prevProjects]
				prevProjectsCopy.push(lastProject)
				setPrevProjects(prevProjectsCopy)
				// Translate, then remove first or last item
				// Raf to be sure items ref array is up to date
				requestAnimationFrame(() => {
					const tl = gsap.timeline({
						onComplete: () => {
							setPrevProjects(oldProjects => oldProjects.filter((_,i) => i !== 0))
						}
					})
					prevListItemsEls.current.forEach(el => {
						if (!el) return
						tl.add(gsap.to(el, 0.6, {
							y: '-100%',
							ease: 'circ.ease',
							onComplete: () => {
								gsap.set(el, {y: 0})
							}
						}), 0)
					})
				})
			}
			else if (currentProjectInfos.direction === -1) {
				let firstProject = projects[currentProjectInfos.index - projectsOffset]
				if (!firstProject) {
					const newIndex = projects.length - (projectsOffset - currentProjectInfos.index)
					firstProject = projects[newIndex]
				}
				const prevProjectsCopy = [...prevProjects]
				prevProjectsCopy.unshift(firstProject)
				setPrevProjects(prevProjectsCopy)
				// Translate, then remove first or last item
				// Raf to be sure items ref array is up to date
				requestAnimationFrame(() => {
					const tl = gsap.timeline({
						onComplete: () => {
							setPrevProjects(oldProjects => oldProjects.filter((_,i) => i !== oldProjects.length -1))
						}
					})
					prevListItemsEls.current.forEach(el => {
						if (!el) return
						tl.add(gsap.set(el, {y: '-100%'}), 0)
						tl.add(gsap.to(el, 0.6, {
							y: 0,
							ease: 'circ.ease'
						}), 0)
					})
				})
			}

		}

		const updateNextListItems = () => {
			if (currentProjectInfos.direction === 1) {
				// Add a new project before or after depending on direction
				let lastProject = projects[currentProjectInfos.index + projectsOffset]
				if (!lastProject) {
					const newIndex = Math.abs(projects.length - (currentProjectInfos.index + projectsOffset))
					lastProject = projects[newIndex]
				}
				const nextProjectsCopy = [...nextProjects]
				nextProjectsCopy.push(lastProject)
				setNextProjects(nextProjectsCopy)
				// Translate, then remove first or last item
				// Raf to be sure items ref array is up to date
				requestAnimationFrame(() => {
					const tl = gsap.timeline({
						onComplete: () => {
							setNextProjects(oldProjects => oldProjects.filter((_,i) => i !== 0))
						}
					})
					nextListItemsEls.current.forEach(el => {
						if (!el) return
						tl.add(gsap.to(el, 0.6, {
							y: '-100%',
							ease: 'circ.ease',
							onComplete: () => {
								gsap.set(el, {y: 0})
							}
						}), 0)
					})
				})
			}
			else if (currentProjectInfos.direction === -1) {
				// Add a new project before or after depending on direction
				let firstProject = projects[currentProjectInfos.index + 1]
				if (!firstProject) { // if we are at the last index of the projects
					firstProject = projects[0]
				}
				const nextProjectsCopy = [...nextProjects]
				nextProjectsCopy.unshift(firstProject)
				setNextProjects(nextProjectsCopy)
				// Translate, then remove first or last item
				// Raf to be sure items ref array is up to date
				requestAnimationFrame(() => {
					const tl = gsap.timeline({
						onComplete: () => {
							setNextProjects(oldProjects => oldProjects.filter((_,i) => i !== oldProjects.length -1))
						}
					})
					nextListItemsEls.current.forEach(el => {
						if (!el) return
						tl.add(gsap.set(el, {y: '-100%'}), 0)
						tl.add(gsap.to(el, 0.6, {
							y: 0,
							ease: 'circ.ease'
						}), 0)
					})
				})
			}
		}

		updatePrevListItems()
		updateNextListItems()
	}, [currentProjectInfos])

	// Update list when current project index change with a go to event (not when inc +1 or -1 or with a canvas init/reinit)
	useEffect(() => {
		if (currentProjectInfos.direction !== 'goTo') return

		const newIndex = currentProjectInfos.index
		projectsListHomeEl.current.classList.add('hidden')

		setTimeout(() => {
			const prevProjects = []
			for (let i = newIndex - projectsOffset; i < newIndex; i += 1) {
				if (projects[i]) {
					prevProjects.push(projects[i])
				} else {
					prevProjects.push(projects[projects.length + i])
				}
			}

			const nextProjects = []
			for (let i = newIndex + 1; i < newIndex + projectsOffset + 1; i += 1) {
				if (projects[i]) {
					nextProjects.push(projects[i])
				} else {
					nextProjects.push(projects[i - projects.length])
				}
			}

			setPrevProjects(prevProjects)
			setNextProjects(nextProjects)

			projectsListHomeEl.current.classList.remove('hidden')
		}, 500);
	}, [currentProjectInfos])

	// Handle click
	const handleProjectClick = project => {
		onProjectClick(project)
	}

	const handleOnProjectKeyDown = (e, project) => {
		if (e.key === 'Enter') {
			handleProjectClick(project)
		}
	}

	return (
		<section
			className="ProjectsListHome"
			ref={projectsListHomeEl}
		>
			<ul
				className="ProjectsListHome__List ProjectsListHome__List--Prev"
				ref={prevListContainerEl}
			>
				{prevProjects.map((project, index) => {
					return (
						<li
							className="ProjectsListHome__ListItem"
							key={`project-list-home-prev-${index}`}
							ref={ref => prevListItemsEls.current[index] = ref }
						>
							<div
								onClick={() => handleProjectClick(project)}
								onKeyDown={e => handleOnProjectKeyDown(e, project)}
								role="button"
								tabIndex={0}
								aria-label="Project"
							>
								{project.data.name.text}
							</div>
						</li>
					)
				})}
			</ul>
			<ul
				className="ProjectsListHome__List ProjectsListHome__List--Next"
				ref={nextListContainerEl}
			>
				{nextProjects.map((project, index) => {
					return (
						<li
							className="ProjectsListHome__ListItem"
							key={`project-list-home-next-${index}`}
							ref={ref => nextListItemsEls.current[index] = ref }
						>
							<div
								onClick={() => handleProjectClick(project)}
								onKeyDown={e => handleOnProjectKeyDown(e, project)}
								role="button"
								tabIndex={0}
								aria-label="Project"
							>
								{project.data.name.text}
							</div>
						</li>
					)
				})}
			</ul>
		</section>
	)
}

export default ProjectsListHome