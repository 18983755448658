import React, { useEffect, useState, useContext } from 'react'
import { graphql, navigate } from 'gatsby'
import { ContextProjectIndex } from '../context'
import ThreeContainer from '../components/ThreeContainer'
import ProjectsCounterHome from '../components/ProjectsCounterHome'
import ProjectsListHome from '../components/ProjectsListHome'
import Seo from '../components/Seo'
import { GlobalEventBus } from '../helpers/eventBus'
import BackgroundAndColors from '../components/BackgroundAndColors.js'

const Homepage = ({ data }) => {

	const [projectIndex, updateProjectIndex] = useContext(ContextProjectIndex)
	const dataProjects = data.prismicHomepage.data.projects.map(p => p.project.document)
	const [projects, setProjects] = useState(dataProjects)

	// Current project logics
	const currentProjectInfosDefault = {
		index: projectIndex,
		direction: null
	}
	const [currentProjectInfos, setCurrentProjectInfos] = useState(currentProjectInfosDefault)

	useEffect(() => {
		GlobalEventBus.on('onHomeSwitchCurrentProject', handleSwitchCurrentProject)
		return () => {
			GlobalEventBus.off('onHomeSwitchCurrentProject', handleSwitchCurrentProject)
		}
	}, [])

	const handleSwitchCurrentProject = e => {
		updateProjectIndex(e.detail.newIndex)
		setCurrentProjectInfos({
			index: e.detail.newIndex,
			direction: e.detail.direction
		})
	}

	// Colors change
	useEffect(() => {
		const newProjectData = projects[currentProjectInfos.index]
		if (!newProjectData) return
		GlobalEventBus.emit('onHomeColorChange', {
			newProjectData: newProjectData
		})
	}, [projects, currentProjectInfos.index])

	// Featured/everything switch
	// const [showFeaturedOnly, setShowFeaturedOnly] = useState(false)
	// const handleFeaturedSwitchClick = () => {
	// 	setShowFeaturedOnly(!showFeaturedOnly)
	// }

	// useEffect(() => {
	// 	if (showFeaturedOnly) {
	// 		setProjects(dataProjects.filter(p => p.data.is_featured === true))
	// 	} else {
	// 		setProjects(dataProjects)
	// 	}
	// }, [showFeaturedOnly])

	// Go to prev or next
	// const goToPrevProject = () => {
	// 	GlobalEventBus.emit('onHomeGoToPrevProject')
	// }

	// const goToNextProject = () => {
	// 	GlobalEventBus.emit('onHomeGoToNextProject')
	// }

	const handleOtherProjectClick = newProject => {
		const newIndex = projects.findIndex(p => p.uid === newProject.uid)
		GlobalEventBus.emit('onHomeGoToProject', { newIndex: newIndex })
	}

	// Click on event receiver
	const handleEventReceiverClick = () => {
		GlobalEventBus.emit('onHomeLeave', {
			dir: 'right'
		})
		navigate(`/${projects[currentProjectInfos.index].uid}`)
	}

  return (
    <main className="Homepage">
			<Seo
				title=""
				description={data.prismicHomepage.data.meta_description.text}
			/>
			<BackgroundAndColors />
			<ThreeContainer
				projects={projects}
				currentProjectIndex={currentProjectInfos.index}
			/>
			<ProjectsCounterHome
				currentIndex={currentProjectInfos.index + 1}
				length={projects.length}
				maxProjectsPossible={dataProjects.length}
			/>
			<ProjectsListHome
				projects={projects}
				currentProjectInfos={currentProjectInfos}
				onProjectClick={handleOtherProjectClick}
			/>
			<div
				className="Homepage__EventReceiver"
				onClick={handleEventReceiverClick}
			></div>
    </main>
  )
}

export default Homepage

export const query = graphql`
	query Homepage {
		prismicHomepage {
			data {
				meta_description {
        	text
      	}
				projects {
					project {
						document {
							... on PrismicProject {
								uid
								data {
									name {
										text
									}
									name_repeat_times
									is_featured
									circular_text_texture {
										url
									}
									image_1 {
										url
									}
									image_2 {
										url
									}
									color_background {
                    color_background_stop
                  }
									color_pagination
									color_description
									color_projects
									color_logo
									color_about_background {
                    color_background_stop
                  }
									color_about_headline
									color_about_logo
									color_transition_background
								}
							}
						}
					}
				}
			}
		}
		prismicLoader {
			...Loader
		}
	}
`
