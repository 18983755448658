import React, { useEffect, useRef } from 'react'
import { GlobalEventBus } from '../helpers/eventBus'

const ProjectsCounterHome = ({ currentIndex, length, maxProjectsPossible }) => {

	const containerEl = useRef(null)

	return (
		<div className="ProjectsCounterHome" ref={containerEl}>
			<div className="ProjectsCounterHome__Current">
				<div
					className="ProjectsCounterHome__CurrentRoller"
					style={{ transform: `translate3d(0, ${-(100 / length) * (currentIndex - 1)}%, 0)` }}
				>
					{[...Array(length)].map((x, i) => {
						return (
							<span
								className="ProjectsCounterHome__CurrentItem"
								key={`projects-counter-home-current-${i}`}
							>
								{i < 9 ? '0' + (i + 1) : i + 1 }
							</span>
						)
					})}
				</div>
			</div>
			<span className="ProjectsCounterHome__Slash"> / </span>
			{/* <span className="ProjectsCounterHome__Max"> {length} </span> */}
			<div className="ProjectsCounterHome__Max">
				<div
					className="ProjectsCounterHome__MaxRoller"
					style={{ transform: `translate3d(0, ${-(100 / maxProjectsPossible) * (length - 1)}%, 0)` }}
				>
					{[...Array(maxProjectsPossible)].map((x, i) => {
						return (
							<span
								className="ProjectsCounterHome__MaxItem"
								key={`projects-counter-home-max-${i}`}
							>
								{i < 9 ? '0' + (i + 1) : i + 1 }
							</span>
						)
					})}
				</div>
			</div>

		</div>
	)
}

export default ProjectsCounterHome