const prepareGradient = (colorStops) => {
	let colorBackground = ''
	if (colorStops.length === 1) {
		colorBackground = colorStops[0].color_background_stop
	} else {
		const stepLength = 100 / (colorStops.length - 1)
		let currentStop = 0
		colorBackground = 'linear-gradient(45deg, '
		for (let i = 0; i < colorStops.length; i += 1) {
			colorBackground += `${colorStops[i].color_background_stop} ${currentStop}%`
			if (i < colorStops.length - 1) {
				colorBackground += ', '
			}
			currentStop += stepLength
		}
		colorBackground += ')'
	}
	return colorBackground
}

export default prepareGradient