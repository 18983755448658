import React, { useEffect, useRef } from 'react'
import ThreeCanvas from './3d/ThreeCanvas'
import { GlobalEventBus } from '../helpers/eventBus.js'

const ThreeContainer = ({ projects, currentProjectIndex }) => {

	const threeContainerEl = useRef(null)

	const threeCanvas = useRef(null)

	// Init
	useEffect(() => {
		// GlobalEventBus.emit('onHomeSwitchCurrentProject', {
		// 	newIndex: currentProjectIndex,
		// 	direction: null
		// })
		// threeContainerEl.current.classList.add('hidden')
		// if (threeCanvas.current) {
		// 	threeCanvas.current.outAnimation()
		// }

		setTimeout(() => {
			if (threeCanvas.current) {
				threeCanvas.current.destroy()
				threeCanvas.current = null
			}
			threeCanvas.current = new ThreeCanvas(projects, currentProjectIndex)
			threeCanvas.current.init(threeContainerEl.current)
			threeCanvas.current.tick()
			threeCanvas.current.inAnimation()
			threeContainerEl.current.classList.remove('hidden')
		}, 700);
	}, [projects])

	// When another project is clicked
	useEffect(() => {
		const handleHomeGoToProject = e => {
			const newIndex = e.detail.newIndex
			GlobalEventBus.emit('onHomeSwitchCurrentProject', {
				newIndex: newIndex,
				direction: 'goTo'
			})
			threeContainerEl.current.classList.add('hidden')
			if (threeCanvas.current) {
				threeCanvas.current.outAnimation()
			}

			setTimeout(() => {
				if (threeCanvas.current) {
					threeCanvas.current.destroy()
					threeCanvas.current = null
				}
				threeCanvas.current = new ThreeCanvas(projects, newIndex)
				threeCanvas.current.init(threeContainerEl.current)
				threeCanvas.current.tick()
				threeCanvas.current.inAnimation()
				threeContainerEl.current.classList.remove('hidden')
			}, 700);
		}

		GlobalEventBus.on('onHomeGoToProject', handleHomeGoToProject)
		return () => {
			GlobalEventBus.off('onHomeGoToProject', handleHomeGoToProject)
		}
	}, [projects])

	// When leaving homepage
	useEffect(() => {
		const handleHomeLeave = e => {
			const dir = e.detail.dir
			threeContainerEl.current.classList.add('hidden')
			if (threeCanvas.current) {
				threeCanvas.current.outAnimation(dir)
			}
			setTimeout(() => {
				if (threeCanvas.current) {
					threeCanvas.current.destroy()
					threeCanvas.current = null
				}
			}, 700);
		}
		GlobalEventBus.on('onHomeLeave', handleHomeLeave)
		return () => {
			GlobalEventBus.off('onHomeLeave', handleHomeLeave)
		}
	})

	// When current project index is changed
	useEffect(() => {
		if (!threeCanvas.current) return
		threeCanvas.current.updateCurrentProjectIndex(currentProjectIndex)
	}, [currentProjectIndex])

	return (
		<div className="ThreeContainer" ref={threeContainerEl}></div>
	)
}

export default ThreeContainer